<template>
  <div class="d-flex flex-column flex-root">
    <div
      class="login login-1 d-flex flex-column flex-lg-row flex-column-fluid bg-white"
      :class="{
        'login-signin-on': this.state == 'signin',
        'login-signup-on': this.state == 'signup',
        'login-forgot-on': this.state == 'forgot',
      }"
      id="kt_login"
    >
      <!--begin::Aside-->
      <div
        class="login-aside d-flex flex-column flex-row-auto"
        style="background-color: #e9ebf5"
      >
        <div class="d-flex flex-column-auto flex-column pt-lg-40 pt-15">
          <a href="#" class="text-center mb-10">
            <img
              src="media/logos/logo-letter-1.png"
              class="max-h-100px"
              alt=""
            />
            <br />
            <img src="media/logos/logo-dark.png" class="max-h-100px" alt="" />
          </a>
          <h3
            class="font-weight-bolder text-center font-size-h4 font-size-h2-lg"
            style="color: #000"
          >
            Your Compliance Officer on Demand Anywhere
          </h3>
        </div>
      </div>
      <!--begin::Aside-->
      <!--begin::Content-->
      <div
        class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto"
      >
        <div class="d-flex flex-column-fluid flex-center">
          <div class="login-form login-signin">
            <form
              class="form"
              novalidate="novalidate"
              id="kt_otp_form"
            >
            <div class="form-group">
                <label class="font-size-h4 font-weight-bolder text-dark">
                  A one-time password (OTP) was just sent to the email address and mobile number registered to this account. 
                  Please open your email or sms to retrieve that OTP and enter it below.
                </label>

                <div id="example-input-group-1" label="" label-for="example-input-1">
                    <!-- <input class="form-control form-control-solid font-size-h6 h-auto py-7 px-6 rounded-lg" type="text" name="otp" ref="otp" v-model="form.otp"/> -->
                    <v-otp-input :length="4" v-model="form.otp"></v-otp-input>
                </div>
                <div class="d-flex justify-content-between mt-n5">
                  <label class="font-size-h6 font-weight-bolder text-dark pt-5"
                    ></label
                  >
                  <a
                    class="text-primary font-size-h6 font-weight-bolder text-hover-primary pt-5"
                    id="kt_login_forgot"
                    @click="onResendOtp"
                    >Resend OTP</a
                  >
                </div>
                 
            </div>

            <div class="pb-lg-0 pb-5">
                <button
                  type="button"
                  ref="kt_otp_submit"
                  class="btn btn-primary font-weight-bolder font-size-h6 px-15 py-4 my-3 mr-3"
                  v-on:click="onSubmitOtp()"
                >
                  Submit
                </button>
            </div>
            </form>
          </div>
          
        </div>
      </div>
      <!--end::Content-->
    </div>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>

<script>
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";

import KTUtil from "@/assets/js/components/util";
import { mapGetters, mapState } from "vuex";
import { LOGIN, LOGOUT, REGISTER, FORGOT_PASSWORD, OTP } from "@/core/services/store/auth.module";
import { STORE_COMPANY } from "@/core/services/store/user.module";
import Swal from "sweetalert2";

export default {
  name: "otp",
  data() {
    return {
      state: "signin",
      form: {
        otp: ""
      },
    };
  },
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
    ...mapGetters(["currentUser"]),

    backgroundImage() {
      return (
        process.env.BASE_URL + "media/svg/illustrations/login-visual-1.svg"
      );
    },
  },

  beforeMount() {
    //if user is not logged in
    if (Object.keys(this.currentUser).length != 0) {
      this.redirectToDashboard();
    }
  },
  mounted() {
    const otp_form = KTUtil.getById("kt_otp_form");

    this.fv = formValidation(otp_form, {
      fields: {
        otp: {
          validators: {
            notEmpty: {
              message: "Otp is required.",
            },
            integer: {
              message: "Please Enter Number Only."
            },
            stringLength: {
              min: 4,
              max: 4,
              message: "OTP must be exactly 4 digits.",
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap(),
      },
    });
  },
  props: {
    email: String,
    password: String,
    company_type: String,
  },
  methods: {
    redirectToDashboard() {
      let context = this;
      context.$router.push({ name: "dashboard" });
    },
    onResendOtp() {
      console.log('Resending OTP to:', this.email, this.password, this.company_type);
      var email = this.email;
      var password = this.password;
      var company_type = this.company_type;
      this.$store.dispatch(LOGOUT);
      const submitButton = this.$refs["kt_otp_resend_submit"];
        submitButton.classList.add("spinner", "spinner-dark", "spinner-right");
        setTimeout(() => {
      this.$store
            .dispatch(LOGIN, { email, password , company_type})
            // go to which page after successfully login
            .then((response) => {
              console.log("RESPONSE")
              console.log(response.user.first_time_login)
              // this.$router.push({ name : "otp"})
              this.$router.push({ name: "otp", params: { email, password, company_type } })
              .catch(err => {
                // Ignore the NavigationDuplicated error
                if (err.name !== 'NavigationDuplicated') {
                  throw err;
                }
              });
            })
            submitButton.classList.remove(
            "spinner",
            "spinner-dark",
            "spinner-right"
          );
        }, 2000);
    },
    onSubmitOtp() {
        this.fv.validate();
        console.log("otp");
        var email = this.email;
        this.fv.on("core.form.valid", () => {
          var otp_input = this.form.otp;

          // clear existing errors
          this.$store.dispatch(LOGOUT);
          
          // set spinner to submit button
          const submitButton = this.$refs["kt_otp_submit"];
          submitButton.classList.add("spinner", "spinner-light", "spinner-right");

          setTimeout(() => {
            // send verify request
            this.$store
              .dispatch(OTP, { otp_input, email})
              // go to which page after successfully verify otp
              .then((response) => {
                console.log("OTP RESPONSE")
                console.log(response);
                
                console.log(response.user.first_time_login)
                console.log('company_type', this.company_type);
                // this.$router.push({ name : "otp"})
                // this.$router.push({ name: "otp", params: { email, password, company_type } });
                if(response.user.first_time_login == '0' && (this.company_type == '1' || this.company_type == '2')) {
                  console.log("DFFDF")
                  this.$router.push({ name : "resetpassword.edit"})
                } else {
                  console.log("XXXX")
                  this.$router.push({ name: "dashboard" });
                }
              });
            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
          }, 2000);
        });
        this.fv.on("core.form.invalid", () => {
        Swal.fire({
          title: "",
          text: "Please, provide correct otp!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false
        });
      });
    }
  },
};
</script>
